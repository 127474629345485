import { RouteRecordRaw } from 'vue-router';

import { Auth } from '$common/modules/auth/middelwares.ts';
import { HasFeature } from '$common/modules/feature-flags/middlewares';

export const routes: RouteRecordRaw[] = [
  {
    path: '/festivals',
    meta: { middlewares: [Auth, HasFeature('booking')] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'FestivalList',
        component: () => import('./views/FestivalList.vue'),
        meta: { title: 'Festivals' },
      },
      {
        path: ':festival',
        component: () => import('./layouts/FestivalLayout.vue'),
        children: [
          {
            path: '',
            name: 'FestivalGeneralView',
            component: () => import('./views/FestivalGeneralView.vue'),
            meta: { title: ':resource:' },
          },
          {
            path: 'shows',
            name: 'FestivalShowsView',
            component: () => import('./views/FestivalShowsView.vue'),
            meta: { title: ':resource: Shows' },
          },
          {
            path: 'contacts',
            name: 'FestivalContactsView',
            component: () => import('./views/FestivalContactsView.vue'),
            meta: { title: ':resource: Contacts' },
          },
          {
            path: 'media',
            name: 'FestivalMediaView',
            component: () => import('./views/FestivalMediaView.vue'),
            meta: { title: ':resource: Media' },
          },
          {
            path: 'logs',
            name: 'FestivalLogsView',
            component: () => import('./views/FestivalLogsView.vue'),
            meta: { title: ':resource: Logs' },
          },
        ],
      },
    ],
  },
];
