import { RouteRecordRaw } from 'vue-router';

import { Auth } from '$common/modules/auth/middelwares.ts';
import { HasFeature } from '$common/modules/feature-flags/middlewares';

export const routes: RouteRecordRaw[] = [
  {
    path: '/invoices',
    meta: { middlewares: [Auth, HasFeature('business')] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'InvoiceList',
        component: () => import('./views/InvoiceList.vue'),
        meta: { title: 'Invoices' },
      },
      {
        path: ':invoice',
        component: () => import('./layouts/InvoiceLayout.vue'),
        children: [
          {
            path: '',
            name: 'InvoiceGeneralView',
            component: () => import('./views/InvoiceGeneralView.vue'),
            meta: { title: ':resource:' },
          },
          {
            path: 'items',
            name: 'InvoiceItemsView',
            component: () => import('./views/InvoiceItemsView.vue'),
            meta: { title: ':resource: Items' },
          },
          {
            path: 'logs',
            name: 'InvoiceLogsView',
            component: () => import('./views/InvoiceLogsView.vue'),
            meta: { title: ':resource: Logs' },
          },
        ],
      },
    ],
  },
];
