import { RouteRecordRaw } from 'vue-router';

import { Auth } from '$common/modules/auth/middelwares.ts';
import { HasFeature } from '$common/modules/feature-flags/middlewares';

export const routes: RouteRecordRaw[] = [
  {
    path: '/artists',
    meta: { middlewares: [Auth] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'ArtistList',
        component: () => import('./views/ArtistList.vue'),
        meta: { title: 'Artists' },
      },
      {
        path: ':artist',
        component: () => import('./layouts/ArtistLayout.vue'),
        children: [
          {
            path: '',
            name: 'ArtistGeneralView',
            component: () => import('./views/ArtistGeneralView.vue'),
            meta: { title: ':resource:' },
          },
          {
            path: 'contacts',
            name: 'ArtistContactsView',
            component: () => import('./views/ArtistContactsView.vue'),
            meta: { title: ':resource: Contacts' },
          },
          {
            path: 'shows',
            name: 'ArtistShowsView',
            component: () => import('./views/ArtistShowsView.vue'),
            meta: {
              title: ':resource: Shows',
              middlewares: [HasFeature('booking')],
            },
          },
          {
            path: 'formations',
            name: 'ArtistFormationsView',
            component: () => import('./views/ArtistFormationsView.vue'),
            meta: { title: ':resource: Formations' },
          },
          {
            path: 'media',
            name: 'ArtistMediaView',
            component: () => import('./views/ArtistMediaView.vue'),
            meta: { title: ':resource: Media' },
          },
          {
            path: 'logs',
            name: 'ArtistLogsView',
            component: () => import('./views/ArtistLogsView.vue'),
            meta: { title: ':resource: Logs' },
          },
        ],
      },
    ],
  },
];
