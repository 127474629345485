import { RouteRecordRaw } from 'vue-router';

import { Auth } from '$common/modules/auth/middelwares.ts';
import { HasFeature } from '$common/modules/feature-flags/middlewares';

export const routes: RouteRecordRaw[] = [
  {
    path: '/venues',
    meta: { middlewares: [Auth] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'VenueList',
        component: () => import('./views/VenueList.vue'),
        meta: { title: 'Venues' },
      },
      {
        path: ':venue',
        component: () => import('./layouts/VenueLayout.vue'),
        children: [
          {
            path: '',
            name: 'VenueGeneralView',
            component: () => import('./views/VenueGeneralView.vue'),
            meta: { title: ':resource:' },
          },
          {
            path: 'shows',
            name: 'VenueShowsView',
            component: () => import('./views/VenueShowsView.vue'),
            meta: {
              title: ':resource: Shows',
              middlewares: [HasFeature('booking')],
            },
          },
          {
            path: 'contacts',
            name: 'VenueContactsView',
            component: () => import('./views/VenueContactsView.vue'),
            meta: { title: ':resource: Contacts' },
          },
          {
            path: 'stages',
            name: 'VenueStagesView',
            component: () => import('./views/VenueStagesView.vue'),
            meta: { title: ':resource: Stages' },
          },
          {
            path: 'media',
            name: 'VenueMediaView',
            component: () => import('./views/VenueMediaView.vue'),
            meta: { title: ':resource: Media' },
          },
          {
            path: 'logs',
            name: 'VenueLogsView',
            component: () => import('./views/VenueLogsView.vue'),
            meta: { title: ':resource: Logs' },
          },
        ],
      },
    ],
  },
];
