import { RouteRecordRaw } from 'vue-router';

import { Auth, HasAccountPermissions } from '$common/modules/auth/middelwares.ts';

export const routes: RouteRecordRaw[] = [
  {
    path: '/activity',
    meta: { middlewares: [Auth, HasAccountPermissions] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'ActivityView',
        component: () => import('./views/ActivityView.vue'),
        meta: { title: 'Activity' },
      },
    ],
  },
];
