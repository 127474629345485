import { RouteRecordRaw } from 'vue-router';

import { Auth } from '$common/modules/auth/middelwares.ts';
import { HasFeature } from '$common/modules/feature-flags/middlewares';

export const routes: RouteRecordRaw[] = [
  {
    path: '/deals',
    meta: { middlewares: [Auth, HasFeature('business')] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'DealList',
        component: () => import('./views/DealList.vue'),
        meta: { title: 'Deals' },
      },
      {
        path: ':deal',
        component: () => import('./layouts/DealLayout.vue'),
        children: [
          {
            path: '',
            name: 'DealGeneralView',
            component: () => import('./views/DealGeneralView.vue'),
            meta: { title: ':resource:' },
          },
          {
            path: 'shows',
            name: 'DealShowsView',
            component: () => import('./views/DealShowsView.vue'),
            meta: {
              title: ':resource: Shows',
              middlewares: [HasFeature('booking')],
            },
          },
          {
            path: 'invoices',
            name: 'DealInvoicesView',
            component: () => import('./views/DealInvoicesView.vue'),
            meta: { title: ':resource: Invoices' },
          },
          {
            path: 'media',
            name: 'DealMediaView',
            component: () => import('./views/DealMediaView.vue'),
            meta: { title: ':resource: Media' },
          },
          {
            path: 'logs',
            name: 'DealLogsView',
            component: () => import('./views/DealLogsView.vue'),
            meta: { title: ':resource: Logs' },
          },
        ],
      },
    ],
  },
];
